import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'

import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

import automation from '../images/services/auto/pragicts-automation-services-network-automation.jpg'
import monitoring from '../images/services/auto/pragicts-automation-services-monitoring.jpg'
import backup from '../images/services/auto/pragicts-automation-services-backup-recovery.jpg'
import virtualization from '../images/services/auto/pragicts-automation-services-virtualization.jpg'
import workflow from '../images/services/auto/pragicts-automation-services-workflow-automation.jpg'
import scripting from '../images/services/auto/pragicts-automation-services-scripting-coding.jpg'
import configuration from '../images/services/auto/pragicts-automation-services-configuration-management.jpg'
import patch from '../images/services/auto/pragicts-automation-services-patch-management.jpg'
import chatbots from '../images/services/auto/pragicts-automation-services-it-services-management.jpg'
import identity from '../images/services/auto/pragicts-automation-services-identity-management.jpg'


const SolutionsPage2 = () => {
    return (
        <Layout pageTitle="PragICTS | Automation | Services">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>SERVICES</h2>
                                <p>PragICTS provides comprehensive services pertaining automation from design,<br/> implementation, management, support and consulting. The scope for automation includes</p>
                                <div className="horizonral-subtitle"><span>SERVICES</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
               
                    <section className="dark-bg no-padding">
                        <div className="hidden-info-wrap-bg">
                            <div className="bg-ser">
                            </div>
                            <div className="overlay"></div>
                        </div>
                        <div className="hidden-info-wrap">
                            <div className="hidden-info fl-wrap">
                                <div className="hidden-works-list fl-wrap">
                                   
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={automation}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Network Automation</h3>
                                                <p>Automation services can also extend to networking tasks, including the configuration and management of network devices, routing, and security policies.</p>
                                             
                                                <div className="clearfix"></div>
                                                <span className="serv-number">01.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={monitoring}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Monitoring and Alerting Automation</h3>
                                                <p>Automated systems can monitor IT infrastructure for performance, security, and other metrics. When predefined thresholds are breached, automated alerts can be triggered to notify administrators or take corrective actions.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">02.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={backup}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Backup and Recovery Automation</h3>
                                                <p>Automating backup processes and creating automated recovery procedures can help safeguard data and minimize downtime in the event of system failures or disasters.</p>
                                              
                                                <div className="clearfix"></div>
                                                <span className="serv-number">03.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={virtualization}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Virtualization and Cloud Automation</h3>
                                                <p>Data storage refers to methods and technologies that capture and retain digital information.</p>
                                                <p>IT automation often extends to virtualized environments and cloud platforms. Automation tools can provision, manage, and scale virtual machines and cloud resources based on demand.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">04.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={workflow}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Workflow Automation</h3>
                                                <p>Automating workflows involves creating a series of tasks that can be executed without manual intervention. This can include tasks such as system provisioning, software deployment, and configuration management.</p>
                                                
                                                <div className="clearfix"></div>
                                                <span className="serv-number">05.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={scripting}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Scripting and Coding</h3>
                                                <p>Automation often involves scripting or coding to create custom solutions tailored to specific needs. Common scripting languages for automation include PowerShell, Python, and Bash.</p>
                                                
                                                <div className="clearfix"></div>
                                                <span className="serv-number">06.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={configuration}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Configuration Management</h3>
                                                <p>Automation services often include tools for managing and maintaining consistent configurations across multiple systems. This ensures that systems are set up and maintained in a standardized and efficient manner.</p>
                                               
                                                <div className="clearfix"></div>
                                                <span className="serv-number">07.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={patch}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Patch Management</h3>
                                                <p>Automating the process of applying patches and updates helps ensure that systems are up-to-date and secure. This includes both operating system and software application patches.</p>
                                               
                                                <div className="clearfix"></div>
                                                <span className="serv-number">08.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={identity}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Identity and Access Management (IAM)</h3>
                                                <p>Automating user provisioning, de-provisioning, and access management helps maintain security and compliance by ensuring that users have the right level of access and permissions.</p>

                                                <div className="clearfix"></div>
                                                <span className="serv-number">09.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={chatbots}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Chatbots and IT Service Management (ITSM)</h3>
                                                <p>Automation in ITSM involves the use of chatbots and other AI-driven tools to handle routine IT service requests, incident resolution, and user support.</p>
                                          
                                                <div className="clearfix"></div>
                                                <span className="serv-number">10.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    
                                    
                                 
                                
                            
                                   









                                </div>
                            </div>
                        </div>
                        <div className="fl-wrap limit-box"></div>
                    </section>

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage2
